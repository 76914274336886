import axios, {AxiosError, InternalAxiosRequestConfig, AxiosRequestConfig, AxiosResponse} from 'axios';

const showStatus = (status: number): string => {
    let message = '';
    switch (status) {
        case 400:
            message = '请求错误(400)';
            break;
        case 401:
            message = '未授权，请重新登录(401)';
            break;
        case 403:
            message = '拒绝访问(403)';
            break;
        case 404:
            message = '请求出错(404)';
            break;
        case 408:
            message = '请求超时(408)';
            break;
        case 500:
            message = '服务器错误(500)';
            break;
        case 501:
            message = '服务未实现(501)';
            break;
        case 502:
            message = '网络错误(502)';
            break;
        case 503:
            message = '服务不可用(503)';
            break;
        case 504:
            message = '网络超时(504)';
            break;
        case 505:
            message = 'HTTP版本不受支持(505)';
            break;
        default:
            message = `连接出错(${status})!`;
    }
    return `${message}，请检查网络或联系管理员！`;
}

const abortControllerMap = new Map();

const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? `https://sansha.chenpe.cn` : 'https://sansha.chenpe.cn',
    proxy: {
        protocol: 'https',
        host: '127.0.0.1',
        port: 8080,
        /*auth: {
            username: 'mikeymike',
            password: 'rapunz3l'
        },*/
    },
    // 联调
    headers: {
        get: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        post: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
    },
    // 跨站点请求时是否需要使用凭证
    withCredentials: false,
    timeout: 300000,
    responseType: 'json',

    // `paramsSerializer`是可选方法，主要用于序列化`params`
    // (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
    /*paramsSerializer: (params) => {
        return Qs.stringify(params, {arrayFormat: 'brackets'});
    },*/

    // `transformRequest` 允许在向服务器发送前，修改请求数据
    // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
    // 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
    /*transformRequest: (params) => {
        return new URLSearchParams(params).toString();
    },*/

    // 使用async-await，处理reject情况较为繁琐，所以全部返回resolve，在业务代码中处理异常
    // `validateStatus` 定义对于给定的HTTP 响应状态码是 resolve 或 reject  promise 。如果 `validateStatus` 返回 `true` (或者设置为 `null` 或 `undefined`)，promise 将被 resolve; 否则，promise 将被 rejecte
    validateStatus (status) {
        return status >= 200 && status < 300; // default
    },
    // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
    transformResponse: [(data) => {
        if (typeof data === 'string' && data.startsWith('{')) {
            data = JSON.parse(data);
        }
        return data;
    }]
});

//
// const axiosdata = axios.post('https://shop.chenpe.com/index.php?s=/api/category/list', {}, {
//         headers: {
//             storeId: 10001,
//             platform: 'H5',
//             "Content-Type": "application/x-www-form-urlencoded",
//         },
//         responseType: 'json',
//     })
//     .then(response => {
//         console.log(response)
//     })
//     .catch(error => {
//         console.error(error);
// });




// 请求拦截器
axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    // config.headers.token = '';
    // config.headers.Authorization = '';

    if (abortControllerMap.get(config.url)) {
        abortControllerMap.get(config.url).abort();
        abortControllerMap.delete(config.url);
    }
    const controller = new AbortController();
    config.signal = controller.signal;
    abortControllerMap.set(config.url, controller);

    return config;
}, (error) => {
    // 错误抛到业务代码
    error.data = {};
    error.data.msg = '服务器异常，请联系管理员！';
    return Promise.resolve(error);
});

// 响应拦截器
axiosInstance.interceptors.response.use((response: AxiosResponse) => {
    // abortControllerMap.get(response.config.url)?.abort();
    // abortControllerMap.delete(response.config.url);

    const status = response.status;
    let msg = '';
    if (status < 200 || status >= 300) {
        // 处理http错误，抛到业务代码
        msg = showStatus(status);
        if (typeof response.data === 'string') {
            response.data = {msg};
        } else {
            response.data.msg = msg;
        }
    }
    return response;
}, (error) => {
    // 错误抛到业务代码
    // error.data = {}
    // error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'
    error.response.data.msg = showStatus(error.response.status);
    return Promise.reject(error);
});

// export default axiosInstance

export const GET = (url: string, config?: AxiosRequestConfig): Promise<any> => {
    return new Promise((resolve, reject) => {
        axiosInstance.get(url, config).then((response: AxiosResponse) => {
            resolve(response.data);
        }).catch((error: AxiosError) => {
            reject(error.response);
        });
    });
}

export const POST = (url: string, params?: {[key: string]: any}, config?: AxiosRequestConfig): Promise<any> => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(url, params, config).then((response: AxiosResponse) => {
            resolve(response.data);
        }).catch((error: AxiosError) => {
            reject(error.response?.data);
        });
    });
}
